import { Title } from '@solidjs/meta';
import { ActivityIndicator, Container, Heading, Page } from '@troon/ui';
import { createAsync } from '@solidjs/router';
import { Show, Suspense } from 'solid-js';
import { clientOnly } from '@solidjs/start';
import dayjs from '@troon/dayjs';
import { produce } from 'solid-js/store';
import { coerceBoolean, createSearchStore } from '../../modules/search-store';
import { Grid, GridThird, GridTwoThirds } from '../../components/layouts/grid';
import { TeeTimeFilters, TeeTimeFiltersFAB } from '../../components/tee-time-search/filters';
import { cachedQuery } from '../../graphql/cached-get';
import { gql } from '../../graphql';
import { schema } from '../../components/tee-time-search/schema';
import { timeFrameNameFromHours } from '../../modules/date-formatting';
import { TeeTimeSearchHeader } from '../../components/tee-time-search/header';
import { TeeTimeBlankData } from '../../components/tee-time-search/blank-data';
import { useUser } from '../../providers/user';
import { Revalidator } from '../../providers/visibility';
import type { RouteDefinition } from '@solidjs/router';

const TeeTimeResults = clientOnly(async () => ({
	default: (await import('../../components/tee-time-search/results')).TeeTimeResults,
}));

export default function TeeTimeSearchPage() {
	const user = useUser();

	const [filters, setFilters] = createSearchStore(
		user()?.me.troonAccessProductType
			? schema.extend({
					access: coerceBoolean().nullish().default(true),
				})
			: schema,
	);

	const facilities = createAsync(
		async () => {
			const query = {
				favorite: filters.favorites,
				location:
					filters.lat && filters.lon ? { latitude: filters.lat, longitude: filters.lon, radiusMiles: 50 } : undefined,
				slugs: filters.facilities,
				regionIds: filters.regionId,
				supportsTroonAccess: filters.access,
				supportsTroonRewards: filters.rewards,
			} satisfies Parameters<typeof getFacilities>[0];

			if (
				!query.regionIds?.length &&
				!query.slugs?.length &&
				(!query.location?.latitude || !query.location?.longitude)
			) {
				return null;
			}
			const res = await getFacilities(query);
			return res?.facilities?.facilities ?? [];
		},
		{ deferStream: true },
	);

	return (
		<Revalidator keys={['teeTimesMultiV2']}>
			<div class="-mb-6 grow bg-neutral-100 md:-mb-12">
				<Title>Book tee times | Troon</Title>
				<Heading as="h1" class="sr-only">
					Search tee times with Troon
				</Heading>

				<TeeTimeSearchHeader
					trackQuery
					onSearchUpdate={() => {
						setFilters(
							produce((s) => {
								s.filterFacilities = null;
							}),
						);
					}}
					filters={filters}
					setFilters={setFilters}
					scrollHeader={
						<>
							<p class="text-xl font-semibold">{filters.query}</p>
							<p class="text-sm text-neutral-800">
								{dayjs(filters.date).format('ddd, MMM D')} • {filters.players} player{filters.players > 1 ? 's' : ''} •{' '}
								{timeFrameNameFromHours(filters.startAt, filters.endAt, true)}
							</p>
						</>
					}
				/>

				<Container>
					<Page class="pt-0 lg:pt-8">
						<Grid>
							<GridThird>
								<div
									// Manual top calculations are depend on the height of the TeeTimeSearchFields section
									// eslint-disable-next-line tailwindcss/no-arbitrary-value
									class="sticky top-[13.5rem] hidden max-h-[calc(100dvh-15rem)] flex-col gap-6 overflow-auto rounded border border-neutral bg-white p-6 lg:flex xl:top-[8.5rem] xl:max-h-[calc(100dvh-11rem)]"
								>
									<Heading as="h2" size="h5">
										Filter by
									</Heading>

									<TeeTimeFilters
										userHasFavorites={!!facilities.latest?.reduce((memo, f) => (memo += f.isFavorite ? 1 : 0), 0)}
										facilities={facilities.latest ?? []}
										filters={filters}
										setFilters={setFilters}
										withFacilityFilters
										withAccessPlusDeals
									/>
								</div>
							</GridThird>

							<GridTwoThirds>
								<Suspense
									fallback={
										<div class="min-h-dvh">
											<ActivityIndicator />
										</div>
									}
								>
									<Show
										when={facilities.latest}
										fallback={<TeeTimeBlankData filters={filters} setFilters={setFilters} />}
									>
										{(facilities) => (
											<TeeTimeResults
												facilities={facilities()}
												filters={filters}
												setFilters={setFilters}
												supportsAccess={!user()?.me.troonAccessProductType}
											/>
										)}
									</Show>
								</Suspense>
							</GridTwoThirds>
						</Grid>

						<div class="lg:hidden">
							<TeeTimeFiltersFAB
								userHasFavorites={!!facilities.latest?.reduce((memo, f) => (memo += f.isFavorite ? 1 : 0), 0)}
								facilities={facilities.latest ?? []}
								filters={filters}
								setFilters={setFilters}
								withFacilityFilters
								withAccessPlusDeals
							/>
						</div>
					</Page>
				</Container>
			</div>
		</Revalidator>
	);
}

const facilityQuery = gql(`query teeTimeFacilitySearchV2(
	$favorite: Boolean,
	$slugs: [String!],
	$regionIds: [String!],
	$location: FacilityLocationInput,
	$supportsTroonAccess: Boolean,
	$supportsTroonRewards: Boolean,
) {
	facilities: facilitiesV3(
		idOrSlugs: $slugs,
		favorite: $favorite,
		regionIds: $regionIds,
		location: $location,
		supportsTroonAccess: $supportsTroonAccess,
		supportsTroonRewards: $supportsTroonRewards,
		types: [DAILY_FEE_RESORT, SEMI_PRIVATE]
	) {
		facilities {
			...TeeTimeFacilityQuery
			...TeeTimeFacilityQueryHeader
			...TeeTimeFacilityFilter
			...TeeTimeAlertForm
			slug
			isFavorite
			timezone
			courses { id, bookingWindowDay { year, month, day } }
		}
	}
}`);

const getFacilities = cachedQuery(facilityQuery);

export const route = { info: { nav: { sticky: false } } } satisfies RouteDefinition;
